import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-432e975e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "misr-cell__container" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["misr-cell", _ctx.styles])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("img", {
        alt: "image",
        src: 
          require(`@/images/misr/${_ctx.animation && _ctx.glow ? 'anim-' + _ctx.digit : 'static-' + _ctx.digit}.webp`)
        ,
        class: _normalizeClass(["misr-cell__item", [
          { 'misr-cell__itemAnimation': _ctx.animation && _ctx.glow, 'misr-cell__item': !_ctx.animation && !_ctx.glow },
          `--${_ctx.digit}`,
        ]])
      }, null, 10, _hoisted_2)
    ])
  ], 2))
}